import React from 'react';
import { Col } from 'react-bootstrap';

const InfoView = () => {
  return (
    <div className="container d-grid text-center ">
      <h1 className="justify-content-center d-grid custom-h1">NLE WHO?</h1>
      <div className="mt-5">
        <Col sm={6} className="text-center mx-auto">
          <p>
            Northern Legacy on organisaatio sinulle joka etsit sitä seuraavaa steppiä ja tahdot lähteä panostamaan
            E-sports- tai someuraasi täysillä! Tarjoamme innovatiivisen yhteisön josta löytyy osaajia monelta alalta.
          </p>
          <p>
            Tuotamme yksilöllistä managerointia tekijän tarpeet huomioiden. Luomme materiaalia, näkyvyyttä ja toimimme
            pilke silmäkulmassa! Tahdomme olla siis erittäin FRESH-tuulahdus liian jäykkiin ja kaavoihinsa
            kangistuneisiin toimintapoihin!
          </p>
          <p>
            Jos kaipaat rentoa ja rempseää mutta silti asiat vakavasti ottavaa yhteisöä tukemaan matkaasi, olemme
            organisaatio juuri sinua varten!
          </p>
        </Col>
      </div>
      <div className="mt-5">
        <p className="m-0">Manageri: Jimi Hirvonen</p>
        <p className="m-0">Moderaattorit: Arizir</p>
        <p className="m-0">Yhteisö: Sami Reinikainen, Tomppeli</p>
        <p className="m-0">Koodaus: Apaiko Oy</p>
        <p className="m-0">Grafiikka: Floto</p>
        <p className="m-0">Selostajat: kaBuum</p>
        <p className="m-0">Video: Jetro Lindsted</p>
        <p className="m-0">Yhteistyöt: Miika Kettu</p>
      </div>
    </div>
  );
};

export default InfoView;
