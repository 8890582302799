import React from 'react';
import { Link } from 'react-router-dom';
import { getMatchDetails } from './misc';
import Image from 'react-bootstrap/Image';
import cslogo from '../resources/images/cs2logo.png';
import valologo from '../resources/images/valorant100x100.png';
import { Col } from 'react-bootstrap';

const gameMap = {
  cs: cslogo,
  valo: valologo,
};

const leagueMap = {
  esea: 'https://images.ctfassets.net/13urdxl5ext2/5iysZkvzbbhISUAQwm7xSx/e9d0bead0f03abc8f9d5c05985761299/eseapysty.png',
  elisa:
    'https://images.ctfassets.net/13urdxl5ext2/5t1yKveLCsSWE9wvPOWPit/d524697e2f936331f5602536a84baf14/elisapysty.png',
  pappa:
    'https://images.ctfassets.net/13urdxl5ext2/65bfKPRlRdMihPYevH1pYR/270956928f95a30f095f6061ba06d418/liigalogoPAPPA.png',
};

export const MatchMap = matches => {
  const mapMatches = matches => {
    return matches?.map(match => {
      const { game, urlSuffix, league } = match;
      const { team1, team2, result1, result2, matchTime1, matchTime2 } = getMatchDetails(match);

      return (
        <div
          key={urlSuffix}
          className="box-shadow custom-rounded-corners mb-5"
          style={{ border: '1px solid rgba(255, 255, 255, 0.1)' }}
        >
          <Link className="no-underline color-white no-hover d-flex w-100" to={'/match/' + urlSuffix}>
            <div className="d-flex flex-column flex-grow-1">
              <div className="d-flex justify-content-center align-items-center">
                <span className="m-0 p-0 custom-h2">
                  {team1} vs {team2}
                </span>
              </div>

              <div className="d-flex justify-content-center">
                <Col>
                  <Image src={match?.t1Logo?.url} style={{ maxHeight: '50px' }} fluid />
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                  {result1 ? (
                    <span>
                      {result1}-{result2}
                    </span>
                  ) : (
                    <span>
                      {matchTime1} {matchTime2}
                    </span>
                  )}
                </Col>
                <Col>
                  <Image src={match?.t2Logo?.url} style={{ maxHeight: '50px' }} fluid />
                </Col>
              </div>

              <div className="d-flex justify-content-center">
                <div>
                  <Image className="w-25 mx-auto" src={gameMap[game || 'cs']} fluid />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-end">
              <div className="h-100" style={{ maxWidth: '40px' }}>
                {league && <Image className="w-100 h-100 custom-rounded-corners2" src={leagueMap[league]} fluid />}
              </div>
            </div>
          </Link>
        </div>
      );
    });
  };
  return mapMatches(matches);
};

export const mapNews = newsList => {
  const options = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  const parseTime = time => {
    return new Date(time).toLocaleString('fi', options);
  };

  return newsList.map(news => (
    <div key={news.id} className="profile-box box-shadow rounded">
      <Link className="no-underline color-white no-hover pl-3" to={'/news/' + news.id}>
        <div style={{ position: 'relative' }}>
          <Image className="rounded-top mb-2 img" src={news?.image?.url} />
          <h3 className="image-label p-3 custom-h2 bg-black" style={{ '--bs-bg-opacity': 0.5 }}>
            {news?.title}
          </h3>
        </div>
        <div className="custom-margin mb-3">
          <span>{news?.shortDescription}</span>
          <h5 className="custom-h5">{parseTime(news?.date)}</h5>
        </div>
      </Link>
    </div>
  ));
};
