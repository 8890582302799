import { Image } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../../store/reducers/session';
import './Main.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { mapNews } from '../utils/MatchMap';
import Paginator from './components/Paginator';

const NewsView = () => {
  const dispatch = useDispatch();
  const topElementRef = useRef(null);
  const { newsCollection, loading } = useSelector(state => state.data);
  const { id } = useParams();

  useEffect(() => {
    if (!newsCollection && !loading) {
      dispatch(fetchData());
    }
    setTimeout(() => {}, 100);
  }, [newsCollection, loading, dispatch]);

  useEffect(() => {
    if (topElementRef.current) {
      topElementRef.current.scrollIntoView({
        behavior: 'instant', // You can use 'instant' or 'smooth'
        block: 'start',
      });
    }
  }, [topElementRef]);

  const news = newsCollection?.find(news => news?.id.toString() === id) || {};

  return !newsCollection ? (
    <div>Loading...</div>
  ) : (
    <React.Fragment>
      <div className="container d-flex justify-content-center align-items-center">
        <div className="text-center">
          <div ref={topElementRef} />
          <div className="d-flex justify-content-center mt-2">
            <Col xs="12" md="6">
              <div className="mx-auto">
                <Image src={news?.image?.url} alt={news.altText} fluid />
              </div>
            </Col>
          </div>
          <h1 className="custom-h1 mt-3 text-center">{news.title}</h1>
          <Col sm={8} className="text-center mx-auto">
            <div dangerouslySetInnerHTML={{ __html: news.content }} />
          </Col>
        </div>
      </div>
      <Row className="mt-5">
        {newsCollection && (
          <div>
            <Paginator data={newsCollection} formatfunc={mapNews} classStyle="news-container gap-3" />
          </div>
        )}
      </Row>
    </React.Fragment>
  );
};

export default NewsView;
