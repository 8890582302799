import React, { useEffect } from 'react';
import Footer from './Footer';
import NavBar from './components/NavBar';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProfiles, fetchMatches, fetchTeams } from '../../store/reducers/session';
import './Main.css';
import CountdownTimer from '../utils/CountdownTimer';
import { ToastContainer } from 'react-toastify';

export default function HomeView({ routes }) {
  const dispatch = useDispatch();

  const { profileCollection, matchCollection, loading, teamsCollection } = useSelector(state => state.data);

  useEffect(() => {
    if (!profileCollection && !loading) {
      dispatch(fetchProfiles());
      if (!matchCollection && !loading) {
        dispatch(fetchMatches());
      }
    }
    if (!teamsCollection && !loading) {
      dispatch(fetchTeams());
    }
  }, [profileCollection, matchCollection, loading, dispatch]);

  return (
    <div className="main">
      {matchCollection && <CountdownTimer className="w-100" matchCollection={matchCollection} />}
      <NavBar profiles={profileCollection} matchCollection={matchCollection} teamsCollection={teamsCollection} />
      <div className="main-route overflow-x-disable">{routes}</div>
      <ToastContainer />
      <Footer />
    </div>
  );
}
