import { Col, Image, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTeams } from '../../store/reducers/session';
import { Link } from 'react-router-dom';
import { leagueMap } from '../utils/misc';

const TeamView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const mapPlayers = profiles => {
    return profiles.map(({ profile, profilePicture }) => (
      <Col className="image-container profile-box p-3 bg-black">
        <span className="image-label p-3 custom-h2 bg-black" style={{ '--bs-bg-opacity': 0.5 }}>
          {profile?.nickname}
        </span>
        <Link to={'/profile/' + profile?.nickname}>
          <Image className="rounded img bg-black box-shadow" src={profilePicture.url} alt={profile?.nickname} />
        </Link>
      </Col>
    ));
  };

  const { teamsCollection, loading } = useSelector(state => state.data);

  useEffect(() => {
    if (!teamsCollection && !loading) {
      dispatch(fetchTeams());
    }
  }, [teamsCollection, loading, dispatch]);

  const team = teamsCollection?.find(team => team?.urlPrefix === id) || {};

  return !teamsCollection ? (
    <div>Loading...</div>
  ) : (
    <div className="container">
      <Row>
        <div className="player-container">{mapPlayers(team?.membersCollection?.items)}</div>
      </Row>
      <Row className="d-flex justify-content-center">
        <Image src={team?.image?.url} className="img w-50" />
      </Row>
      <Row>
        <div className="league-container">
          {team?.leagues &&
            team?.leagues.map((league, index) => (
              <div className="league" key={index}>
                <a href={leagueMap[league].url} target="_blank" rel="noreferrer">
                  <Image src={leagueMap[league].logo} className="img" />
                </a>
              </div>
            ))}
        </div>
      </Row>
    </div>
  );
};

export default TeamView;
