import { Link } from 'react-router-dom';
import { Row, Col, Image, NavDropdown, Navbar, Nav } from 'react-bootstrap';
import logouusi_esl from '../../resources/images/logouusi_esl.png';
import './NavBar.css';
import React, { useEffect, useState, useCallback } from 'react';
import { FaYoutube, FaInstagram, FaTwitterSquare, FaTiktok, FaTelegram } from 'react-icons/fa';
import { MatchMap } from '../../utils/MatchMap.js';
import Paginator from './Paginator';
import _ from 'lodash';
import forttilogo from '../../resources/images/forttilogo.png';

const NavBar = ({ profiles, matchCollection, teamsCollection }) => {
  const [mappedProfiles, setMappedProfiles] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleToggle = useCallback(() => {
    setCollapsed(prevCollapsed => !prevCollapsed);
  }, []);

  const CustomDivider = ({ title, iconImageUrl }) => (
    <div className="text-center">
      <div className="dropdown-divider-title mt-1">
        <span style={{ color: 'orange' }}>
          <img src={iconImageUrl} alt="Icon" style={{ height: '20px' }} />{' '}
        </span>
        <span className="ml-2">{title}</span>
      </div>
    </div>
  );

  useEffect(() => {
    const mapProfiles = teamsCollection => {
      if (!teamsCollection) return;

      return (
        <>
          {streamerProfiles.length > 0 && (
            <NavDropdown title="Sometalli" id="nav-dropdown-streamers" menuVariant="dark">
              {streamerProfiles.map(item =>
                item.profile ? (
                  <NavDropdown.Item
                    key={item.profile.nickname}
                    as={Link}
                    onClick={handleToggle}
                    to={`/profile/${item.profile.nickname}`}
                  >
                    {item.profile.nickname}
                  </NavDropdown.Item>
                ) : (
                  item
                )
              )}
            </NavDropdown>
          )}
          {teamsCollection.map(team => {
            // Extract the team name or urlPrefix as the category for the team
            const teamName = team.game;

            return (
              <NavDropdown key={teamName} title={teamName} id={`nav-dropdown-${teamName}`} menuVariant="dark">
                {/* Map profiles for each team */}
                {team.membersCollection.items.map(item =>
                  item.profile ? (
                    <NavDropdown.Item
                      key={item.profile.nickname}
                      as={Link}
                      onClick={handleToggle}
                      to={`/profile/${item.profile.nickname}`}
                    >
                      {item.profile.nickname}
                    </NavDropdown.Item>
                  ) : (
                    item
                  )
                )}
              </NavDropdown>
            );
          })}
        </>
      );
    };

    const [streamerProfiles, normalProfiles] = _.partition(profiles, ({ isStreamer }) => isStreamer);

    setMappedProfiles(mapProfiles(teamsCollection));
  }, [profiles, handleToggle]);

  useEffect(() => {
    // Function to update the isMobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="overflow-x-disable w-100 nav-row">
      <div className="container">
        <Row>
          <Col sm={2}>
            <Image className="nav-logo" src={logouusi_esl} />
          </Col>

          <Col sm={10}>
            <Row>
              <div className="d-flex flex-row-reverse gap-2 px-md-5">
                <a href={'https://twitter.com/northern_legacy'} target="_blank" rel="noreferrer">
                  <FaTwitterSquare />
                </a>
                <a href={'https://www.instagram.com/northernlegacyesports'} target="_blank" rel="noreferrer">
                  <FaInstagram />
                </a>
                <a href="https://www.youtube.com/@NLEFI" target="_blank" rel="noreferrer">
                  <FaYoutube />
                </a>
                <a href={'https://www.tiktok.com/@nlefi.gg?_t=8ghmOqLvwXp&_r=1'} target="_blank" rel="noreferrer">
                  <FaTiktok />
                </a>
                <a href={'https://t.me/NLE_TG'} target="_blank" rel="noreferrer">
                  <FaTelegram />
                </a>
              </div>
            </Row>
            <Navbar className="nav-bar" expand="md" bg="black" variant="dark">
              <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
              <Navbar.Collapse in={!collapsed}>
                <Nav className="me-auto mw-100 justify-content-center w-100 gap-3">
                  <Nav.Link as={Link} onClick={handleToggle} to="/">
                    Koti
                  </Nav.Link>
                  <Nav.Link as={Link} onClick={handleToggle} to="/roster">
                    Some
                  </Nav.Link>
                  <Nav.Link as={Link} onClick={handleToggle} to="/esports">
                    Esports
                  </Nav.Link>
                  <NavDropdown title="Lauma" menuVariant="dark">
                    {mappedProfiles}
                  </NavDropdown>
                  <Nav.Link as={Link} onClick={handleToggle} to="/sponsors">
                    Yhteistyökumppanit
                  </Nav.Link>
                  <Nav.Link as={Link} onClick={handleToggle} to="/marketing">
                    NLE markkinoi
                  </Nav.Link>
                  <Nav.Link as={Link} onClick={handleToggle} to="/info">
                    NLE who?
                  </Nav.Link>
                  <Nav.Link
                    onClick={handleToggle}
                    href="https://www.nleshop.fi"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NLESHOP
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
        <Row>
          <Paginator
            data={matchCollection}
            formatfunc={MatchMap}
            classStyle="text-center match-container mt-2"
            hidePage
            size={isMobile ? 1 : 3}
            useScroll={false}
          />
        </Row>
      </div>
    </div>
  );
};

export default NavBar;
