import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import perkka_speak from '../resources/images/pekka_speak1.jpg';
import unbox from '../resources/images/unbox.png';
import chat from '../resources/images/chattiphoto.png';
import lan from '../resources/images/lan.jpg';
import Logomarkkinointipaikat from '../resources/images/pekka_peace.PNG';
import './ContactView.css';
import Image from 'react-bootstrap/Image';
import StreamerMap from '../utils/StreamerMap.js';
import { Link } from 'react-router-dom';

const ContactView = () => {
  return (
    <div className="container">
      <Row>
        <Col sm={8}>
          <h1 className="custom-h1">Yhteistyöt ja logopaikat</h1>
          <span>
            Voit saada oman yrityksesi linkin chat-komentona pyörimään live-lähetyksessä! Tietyin väliajoin ilmestyvä
            muistutus ottaa yrityksesi haltuun ja tuo varmasti liikennettä verkkosivuillesi!
            <br />
            <Link className="no-underline" style={{ color: 'orange' }} to="/contact">
              Aloita yhteystyö tästä!
            </Link>
          </span>
          <Row className="mt-5">
            <Row>
              <Col md={6}>
                <h2 className="custom-h2">Personoidut chat komennot</h2>
                Voit saada oman yrityksesi linkin chat-komentona pyörimään live lähetyksessä! Tietyin väliajoin
                ilmestyvä muistutus ottaa yrityksesi haltuun, tuo varmasti liikennettä verkkosivuillesi!
              </Col>
              <Col md={6} className="order-first order-md-last mb-2">
                <Image className="img rounded mb-2" src={chat} alt="twitch chat" />
              </Col>
            </Row>
          </Row>
          <Row className="mt-5">
            <Row>
              <Col md={6}>
                <Image className="img rounded mb-2" src={Logomarkkinointipaikat} alt="Pekka showing peace mark" />
              </Col>
              <Col md={6}>
                <h2 className="custom-h2">Logomarkkinointipaikat</h2>
                Voit ostaa näkyvyyttä yrityksesi logolle lähetyksissämme! Logopaikka tavoittaa live-yleisöä jatkuvasti
                aktiivissa striimeissämme!
                <Row>
                  <Link className="no-underline" style={{ color: 'orange' }} to="/contact">
                    Kysy rohkeasti!
                  </Link>
                </Row>
              </Col>
            </Row>
          </Row>
          <Row className="mt-5">
            <Row>
              <Col md={6}>
                <h2 className="custom-h2">Näkyvyyttä alan tapahtumissa</h2>
                Organisaatiomme osallistuu aktiivisesti alan tapahtumiin! Voit siis lähteä mukaan kumppaniksi ja päästä
                näkyville ympäri Suomea lajin ykköstapahtumissa!
                <br />
              </Col>
              <Col md={6} className="order-first order-md-last mb-2">
                <Image className="img rounded mb-2" src={lan} alt="Lan event" />
              </Col>
            </Row>
          </Row>
          <Row className="mt-5">
            <Row>
              <Col md={6}>
                <Image className="img rounded mb-2" src={perkka_speak} alt="Pekka puhumassa" />
              </Col>
              <Col md={6}>
                <h2 className="custom-h2">Yritysvierailut</h2>
                Vaikuttajamme tekevät yritysvierailuja joissa yrityksesi palvelut simuloidaan ja palaute haetaan
                vaikuttajamme yhteisöltä!
              </Col>
            </Row>
          </Row>
          <Row className="mt-5">
            <Row>
              <Col md={6}>
                <h2 className="custom-h2">Unboxin/tuotearvostelut</h2>
                Teemme tuotearvosteluja sekä ns. "UnBoxin"-videoita, joissa tuotteesi ensikohtaaminen taltioidaan ja
                saat taatusti rehellistä palautetta.
              </Col>
              <Col md={6} className="order-first order-md-last mb-2 d-flex justify-content-center align-items-center">
                <Image className="img rounded mb-2 w-50" src={unbox} alt="unboxing logo" />
              </Col>
            </Row>
          </Row>
          <Link className="no-underline" to="/contact">
            <span className="custom-h3">Ota yhteyttä!</span>
          </Link>
        </Col>
        <Col sm={4}>
          <div className="streamer-marketing-container">
            <StreamerMap />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactView;
