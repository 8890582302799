import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import milli_logo from '../resources/images/milli_logo.png';
import grolls_logo from '../resources/images/grolls.png';
import osirion_logo from '../resources/images/osirionPNG.png';
import './ContactView.css';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';

const sponsors = [
  {
    url: 'https://www.millibaari.fi',
    text: 'Millibaari on Vehmersalmella sijaitseva kesäravintola joka on mukana mahdollistamassa Esports toimintaamme.<br/>Millibaari palvelee niin janoista kuin nälkäistäkin kulkijaa!',
    image: milli_logo,
    alt: 'Millibaari logo',
  },
  {
    url: 'https://www.grolls.fi',
    text: 'GROLLS on työvaatteiden ja suojaimien asiantuntija!<br/>NLEshopin tuotteet valmistuvatkin ammattitaidolla suoraan savon syrämestä!<br/>Jos kaipaat asiantuntevaa ja mutkatonta palvelua, on GROLLS juuri oikea valinta!',
    image: grolls_logo,
    alt: 'GROLLS logo',
  },
  {
    url: 'https://www.osirion.gg',
    text: 'Osirion on tekoälyvalmentaja Fortnite-pelaajille. Osirion tarjoaa myös työkaluja striimaajille ja on edelläkävijä tekoälyn käytössä Fortnite-valmennuksessa! Rekisteröityessä käytä koodia NLEFI !',
    image: osirion_logo,
    alt: 'Osirion logo',
  },
];

const parseSponsor = s => {
  return s.map(({ url, text, image, alt }) => {
    console.log({ url });
    const urlWithoutPrefix = url.replace('https://', '');
    return (
      <Row>
        <Col sm={4} className="align-self-center">
          <Image className="img rounded" src={image} alt={alt} />
        </Col>
        <Col sm={8} className="pt-5 pb-5">
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <br />
          <a href="url" className="no-underline" target="_blank" rel="noreferrer">
            {urlWithoutPrefix}
          </a>
        </Col>
      </Row>
    );
  });
};

const spons = parseSponsor(sponsors);
const ContactView = () => {
  return (
    <div className="container">
      <h1 className="justify-content-center d-grid custom-h1">Yhteistyökumppanit</h1>
      {spons}
      <Row className="text-center mt-5">
        <Link className="no-underline" to="/marketing">
          <h2 className="custom-h2">
            Haluatko yhteistyökumppaniksi? <br />
            Kiinnostaako näkyvyys NLE:n toiminnassa?
          </h2>
        </Link>
      </Row>
    </div>
  );
};

export default ContactView;
